import { basePageMixin } from '@/mixins/basePageMixin'
import { beforeRouteLeaveCommon } from './common'
import { ItemNotFoundError } from '@/utils/consts'
import _ from '@/utils/lodash'

export const viewPageMixin = {
  mixins: [basePageMixin],
  data () {
    return {
      // moreInfoRows puede ser:
      // 1º un array strings con nombres de campos
      // ['idcliente', 'nombre']
      // 2º un array de objetos (campos)
      // [{ name: 'idcliente' }, { name: 'nombre' }]
      // 3º un objeto con grupos y cada grupo con un título y un array de strings u objetos (campos)
      // {
      //   cliente: { title: 'Cliente', rows: ['idcliente', 'nombre'] },
      //   sistema: { title: 'Sistema', rows: [ { name: 'idsistema' }, 'descripcion'] },
      // },
      item: {
        dataset: [],
        metadata: {},
      },
      moreInfoRows: [],
      details: [],
    }
  },
  methods: {
    async initStore () {
      await this.copyRouteParamsToPageStore()
    },
    async beforeRouteLeaveBase (to, from, next) {
      if (!beforeRouteLeaveCommon(this, to, from, next)) {
        if (this.pageStorePath) {
          await this.dispatchStore('resetStore')
        }
        next()
      }
    },
    buildMoreInfo (metadata) {
      const getRowLabelFromMetadata = (rowName, metadata) => {
        let label = ''
        if (metadata && typeof metadata.getColumn === 'function') {
          const metadataRow = metadata.getColumn(rowName)
          if (metadataRow) {
            label = metadataRow.label
          }
        }
        return label || this.$options.filters.capitalize(rowName)
      }
      const defaultProperties = {
        visible: true,
        clickable: false,
        password: false,
        icon: this.$vuetify.icons.values.next
      }
      if (Array.isArray(this.moreInfoRows)) {
        const moreInfoArray = []
        for (const row of this.moreInfoRows) {
          let formattedRow = {}
          if (typeof(row) === 'string') {
            formattedRow = { name: row, label: getRowLabelFromMetadata(row, metadata) }
          } else {
            formattedRow = row
            if (!formattedRow.label) {
              formattedRow.label = getRowLabelFromMetadata(formattedRow.name, metadata)
            }
          }
          moreInfoArray.push({ ...defaultProperties, ...formattedRow })
        }
        return moreInfoArray
      } else {
        const moreInfoObj = {}
        for (const group in this.moreInfoRows) {
          moreInfoObj[group] = {}
          moreInfoObj[group].title = this.moreInfoRows[group].title
          moreInfoObj[group].rows = []
          for (const row of this.moreInfoRows[group].rows) {
            let formattedRow = {}
            if (typeof(row) === 'string') {
              formattedRow = { name: row, label: getRowLabelFromMetadata(row, metadata) }
            } else {
              formattedRow = row
              if (!formattedRow.label) {
                formattedRow.label = getRowLabelFromMetadata(formattedRow.name, metadata)
              }
            }
            moreInfoObj[group].rows.push({ ...defaultProperties, ...formattedRow })
          }
        }
        return moreInfoObj
      }
    },
    initDetails () {
      this.details = []
    },
    addDetail (name, title, subtitle, icon, disabled = false, data = {}) {
      const index = this.details.push({
        type: 'detail',
        name,
        title,
        subtitle,
        icon,
        order: this.details.length + 1,
        badge: '',
        badgeColor: '',
        totals: '',
        data,
        disabled,
      })
      return this.details[index - 1]
    },
    addDetailHeader (title) {
      const index = this.details.push({
        type: 'header',
        title,
        order: this.details.length + 1,
      })
      return this.details[index - 1]
    },
    setMoreInfoRowValue (name, prop, value, group = '') {
      // ¿esta agrupado?
      if (group) {
        _.find(this.moreInfoRows[group].rows, { name })[prop] = value
      } else {
        _.find(this.moreInfoRows, { name })[prop] = value
      }
    },
    itemNotFound (message) {
      throw new ItemNotFoundError(message)
    },
  }
}
