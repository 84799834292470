<template>
  <div>
    <v-divider />
    <v-subheader>
      Ir a ...
    </v-subheader>
    <v-list-item
      @click.stop="clickIrAParte"
    >
      <v-list-item-avatar>
        <v-icon>{{ $vuetify.icons.values.parte }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>Parte de trabajo</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="idchecklistOt"
      @click.stop="clickIrAChecklist"
    >
      <v-list-item-avatar>
        <v-icon>{{ $vuetify.icons.values.checklist }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>Checklist</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="idchecklistOt && (idordenTrabajoMatsist || idgrupoChecklistOt)"
      @click.stop="clickIrAPreguntasChecklist"
    >
      <v-list-item-avatar>
        <v-icon>{{ $vuetify.icons.values.preguntaChecklist }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>Preguntas</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="idparteTrabajoMatsist"
      @click.stop="clickIrAMaterialAfectado"
    >
      <v-list-item-avatar>
        <v-icon>{{ $vuetify.icons.values.materialAfectado }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>Material</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="idsubsis"
      @click.stop="clickIrASubsis"
    >
      <v-list-item-avatar>
        <v-icon>{{ $vuetify.icons.values.subsistema }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>Lista de materiales</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    idparteTrabajo: {
      type: [Number, String],
      default: '',
    },
    idchecklistOt: {
      type: [Number, String],
      default: '',
    },
    idordenTrabajoMatsist: {
      type: [Number, String],
      default: '',
    },
    idparteTrabajoMatsist: {
      type: [Number, String],
      default: '',
    },
    idgrupoChecklistOt: {
      type: [Number, String],
      default: '',
    },
    idsubsis: {
      type: [Number, String],
      default: '',
    },
  },
  methods: {
    clickIrAParte () {
      this.$appRouter.push({
        name: 'offline__parte-trabajo-view',
        params: {
          idparte_trabajo: this.idparteTrabajo,
        },
      })
    },
    clickIrAChecklist () {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: this.idparteTrabajo,
          idchecklist_ot: this.idchecklistOt,
        }
      })
    },
    clickIrAPreguntasChecklist () {
      if (this.idordenTrabajoMatsist) {
        this.$appRouter.push({
          name: 'offline__materiales-pregunta-list',
          params: {
            idparte_trabajo: this.idparteTrabajo,
            idchecklist_ot: this.idchecklistOt,
            idorden_trabajo_matsist: this.idordenTrabajoMatsist,
          },
        })
      } else {
        this.$appRouter.push({
          name: 'offline__grupos-pregunta-list',
          params: {
            idparte_trabajo: this.idparteTrabajo,
            idchecklist_ot: this.idchecklistOt,
            idgrupo_checklist_ot: this.idgrupoChecklistOt,
          },
        })
      }
    },
    clickIrAMaterialAfectado () {
      this.$appRouter.push({
        name: 'offline__material-afectado-view',
        params: {
          idparte_trabajo: this.idparteTrabajo,
          idparte_trabajo_matsist: this.idparteTrabajoMatsist,
        }
      })
    },
    clickIrASubsis () {
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.idparteTrabajo,
        },
        query: {
          idsubsis: this.idsubsis,
        },
      })
    },
  },

}
</script>
