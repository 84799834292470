export default {
  async selectLparteTrabajo (Vue, idlparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.parte_trabajo,
        tables.lparte_trabajo.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.articulo,
        tables.lparte_trabajo.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subsis,
        tables.lparte_trabajo.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .leftOuterJoin(
        tables.tmotivo_nofacturable,
        tables.lparte_trabajo.idtmotivo_nofacturable.eq(tables.tmotivo_nofacturable.idtmotivo_nofacturable)
      )
      .leftOuterJoin(
        tables.fabricante,
        tables.lparte_trabajo.idfabricante.eq(tables.fabricante.idfabricante)
      )
      .leftOuterJoin(
        tables.agente_extintor,
        tables.lparte_trabajo.idagente_extintor.eq(tables.agente_extintor.idagente_extintor)
      )
      .leftOuterJoin(
        tables.agente_propelente,
        tables.lparte_trabajo.idagente_propelente.eq(tables.agente_propelente.idagente_propelente)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.estado.gt(0),
          tables.lparte_trabajo.idlparte_trabajo.eq(idlparteTrabajo)
        )
      )
      .exec())[0]
  },
  async selectLordenTrabajoDetalleCount (Vue, idlparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.lorden_trabajo_detalle.idlorden_trabajo_detalle).as('count'))
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.lorden_trabajo,
        tables.lparte_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
      )
      .innerJoin(
        tables.lorden_trabajo_detalle,
        tables.lorden_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo_detalle.idlorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.lorden_trabajo_detalle.estado.gt(0),
          tables.lparte_trabajo.idlparte_trabajo.eq(idlparteTrabajo)
        )
      )
      .exec())[0]
  },
  async selectSiguienteAnteriorMaterial (Vue, idparteTrabajo, orden, idsubsis, siguiente) {
    const tables = Vue.$offline.db.tables
    let materialIrA = Vue.$offline.db
      .select()
      .from(tables.lparte_trabajo)
    if (siguiente) {
      materialIrA = await materialIrA
        .where(
          Vue.$offline.db.op.and(
            tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
            tables.lparte_trabajo.idsubsis.eq(idsubsis),
            tables.lparte_trabajo.orden.gt(orden)
          )
        )
        .orderBy(tables.lparte_trabajo.orden)
        .exec()
    } else {
      materialIrA = await materialIrA
        .where(
          Vue.$offline.db.op.and(
            tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
            tables.lparte_trabajo.idsubsis.eq(idsubsis),
            tables.lparte_trabajo.orden.lt(orden)
          )
        )
        .orderBy(tables.lparte_trabajo.orden, Vue.$offline.db.order.DESC)
        .exec()
    }
    if (materialIrA.length > 0) {
      return materialIrA[0]
    }
  },
}
